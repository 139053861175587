import {
    FreStage,
    TenantInfo,
    useCreateTenantInfo,
    useGetTenantInfo,
    usePatchTenantSettings,
} from '@/api/tenant';
import {useUserState} from '../user';
import {ErrorResponse} from '../api';

export interface ManageTenantInfoProps {
    onFetchTenantError?: (error: any) => void;
}

export default function useManageTenantInfo(props?: ManageTenantInfoProps) {
    const {refetch: refetchTenantInfo} = useGetTenantInfo({
        enabled: false,
    });

    const {tenant: userTenant} = useUserState();

    const {mutateAsync: sendTenantInfo} = useCreateTenantInfo();

    const {mutateAsync: patchTenantInfo} = usePatchTenantSettings();

    const patchFreMetadata = function (
        freStage: FreStage,
        isFreComplete: boolean = false,
    ): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const {data: tenantInfo} = await refetchTenantInfo();

                if (!tenantInfo) {
                    reject('Tenant info not available');
                    return;
                }

                if (!tenantInfo?.freRequirements?.freStage) {
                    reject('Tenant FRE info not available');
                }

                if (
                    tenantInfo?.freRequirements?.freRequirementsMet === isFreComplete &&
                    tenantInfo?.freRequirements?.freStage === freStage
                ) {
                    // No need to patch if the values are the same
                    resolve();
                }

                tenantInfo.freRequirements = {
                    ...tenantInfo?.freRequirements,
                    freRequirementsMet: isFreComplete,
                    freStage: freStage,
                };

                const patchedTenantResult = await patchTenantInfo(tenantInfo.freRequirements);

                if (patchedTenantResult) {
                    resolve();
                } else {
                    reject('Failed to patch tenant info');
                }
            } catch (error) {
                console.log('error', error);
                reject(error);
            }
        });
    };

    const initializeTenantFreInfo = function (): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const {data: tenantInfo, error} = await refetchTenantInfo();
                const errorData = error as ErrorResponse;

                if (!tenantInfo || errorData?.code === 404) {
                    const tenantInfoRequest: TenantInfo = {
                        enabled: false,
                        tenantId: userTenant?.tenantId,
                        name: userTenant?.displayName,
                        featureFlags: [],
                        freRequirements: {
                            freRequirementsMet: false,
                            freStage: FreStage.TenantCreation,
                        },
                    };

                    const result = await sendTenantInfo(tenantInfoRequest);
                    if (result) {
                        resolve();
                    } else {
                        reject('Failed to create tenant info');
                    }
                } else {
                    await patchFreMetadata(FreStage.TenantCreation);
                    resolve();
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    return {
        patchFreMetadata,
        initializeTenantFreInfo,
    };
}
