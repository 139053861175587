import MedeinaVariables from '@/util/variables';
import {Link, mergeClasses} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import usePageClasses from './AccessRoleInfo.styles';
import {ResponsivePersona} from '@/components/ui/Persona/ResponsivePersona';
import {ParagraphContent, SmallFooterContent, TextSubtitle2} from '@/components/ui/Text';

export default function RoleInfoContent() {
    const {t} = useTranslation('tours');

    const tourClasses = useTourClasses();
    const pageClasses = usePageClasses();

    return (
        <>
            <div className={mergeClasses(pageClasses.supplementaryContentWidth)}>
                <div className={tourClasses.tourSectionSpacing}>
                    <TextSubtitle2>{t('adminV2Fre.roleInfo.ContributorsTitle')}</TextSubtitle2>
                </div>
                <div className={tourClasses.tourSectionSpacing}>
                    <ParagraphContent>
                        {t('adminV2Fre.roleInfo.ContributorsContent')}
                    </ParagraphContent>
                </div>
            </div>
            <div
                className={mergeClasses(
                    pageClasses.roleContainer,
                    tourClasses.tourXLargeSectionSpacing,
                )}
            >
                <ResponsivePersona
                    content={t('adminV2Fre.roleInfo.Everyone')}
                    color="cornflower"
                    classes={pageClasses.contributorRolePersona}
                />
            </div>
            <div className={mergeClasses(pageClasses.supplementaryContentWidth)}>
                <div className={tourClasses.tourSectionSpacing}>
                    <TextSubtitle2>{t('adminV2Fre.roleInfo.OwnersTitle')}</TextSubtitle2>
                </div>
                <div className={tourClasses.tourSectionSpacing}>
                    <ParagraphContent>{t('adminV2Fre.roleInfo.OwnersContent')}</ParagraphContent>
                </div>
            </div>

            <div
                className={mergeClasses(
                    pageClasses.roleContainer,
                    tourClasses.tourXLargeSectionSpacing,
                )}
            >
                <ResponsivePersona
                    content={t('adminV2Fre.roleInfo.GlobalAdministrator')}
                    color="lilac"
                    classes={pageClasses.ownerRolePersona}
                />
                <ResponsivePersona
                    content={t('adminV2Fre.roleInfo.SecurityAdministrator')}
                    color="cornflower"
                    classes={pageClasses.ownerRolePersona}
                />
            </div>
            <div>
                <SmallFooterContent>
                    <Link
                        href={MedeinaVariables.SecurityCopilotAuthenticationUri}
                        target="_blank"
                        aria-label={t('adminV2Fre.roleInfo.ariaLabel.')}
                    >
                        {t('adminV2Fre.roleInfo.LearnMorePermissions')}
                    </Link>
                </SmallFooterContent>
            </div>
        </>
    );
}
