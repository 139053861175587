import MedeinaFeatures, {MedeinaFeatureFlagValue} from '@/util/features';
import {TOUR_STEPS} from './stops/AdminTour.types';

export const getAdminTourStops = (
    isAuditLoggingEnabled: MedeinaFeatureFlagValue,
): Partial<Record<TOUR_STEPS, number>> => {
    let adminTourSteps: Partial<Record<TOUR_STEPS, number>> = {
        [TOUR_STEPS.Welcome]: 1,
        [TOUR_STEPS.CreateCapacity]: 2,
        [TOUR_STEPS.SelectCapacity]: 2,
        [TOUR_STEPS.Locations]: 3,
        [TOUR_STEPS.DataAndPrivacy]: 4,
        [TOUR_STEPS.RoleInfo]: 5,
        [TOUR_STEPS.TourCompleted]: 6,
    };

    if (MedeinaFeatures.EnableGeoSelection && MedeinaFeatures.EnableO365ConsentStepInFRE) {
        if (isAuditLoggingEnabled) {
            adminTourSteps = {
                [TOUR_STEPS.Welcome]: 1,
                [TOUR_STEPS.SelectLocation]: 2,
                [TOUR_STEPS.CreateCapacity]: 3,
                [TOUR_STEPS.SelectCapacity]: 3,
                [TOUR_STEPS.Locations]: 4,
                [TOUR_STEPS.DataAndPrivacy]: 5,
                [TOUR_STEPS.O365Consent]: 6,
                [TOUR_STEPS.AuditLoggingConsent]: 7,
                [TOUR_STEPS.RoleInfo]: 8,
                [TOUR_STEPS.TourCompleted]: 9,
            };
        } else {
            adminTourSteps = {
                [TOUR_STEPS.Welcome]: 1,
                [TOUR_STEPS.SelectLocation]: 2,
                [TOUR_STEPS.CreateCapacity]: 3,
                [TOUR_STEPS.SelectCapacity]: 3,
                [TOUR_STEPS.Locations]: 4,
                [TOUR_STEPS.DataAndPrivacy]: 5,
                [TOUR_STEPS.O365Consent]: 6,
                [TOUR_STEPS.RoleInfo]: 7,
                [TOUR_STEPS.TourCompleted]: 8,
            };
        }
    } else if (MedeinaFeatures.EnableO365ConsentStepInFRE) {
        if (isAuditLoggingEnabled) {
            adminTourSteps = {
                [TOUR_STEPS.Welcome]: 1,
                [TOUR_STEPS.CreateCapacity]: 2,
                [TOUR_STEPS.SelectCapacity]: 2,
                [TOUR_STEPS.Locations]: 3,
                [TOUR_STEPS.DataAndPrivacy]: 4,
                [TOUR_STEPS.O365Consent]: 5,
                [TOUR_STEPS.AuditLoggingConsent]: 6,
                [TOUR_STEPS.RoleInfo]: 7,
                [TOUR_STEPS.TourCompleted]: 8,
            };
        } else {
            adminTourSteps = {
                [TOUR_STEPS.Welcome]: 1,
                [TOUR_STEPS.CreateCapacity]: 2,
                [TOUR_STEPS.SelectCapacity]: 2,
                [TOUR_STEPS.Locations]: 3,
                [TOUR_STEPS.DataAndPrivacy]: 4,
                [TOUR_STEPS.O365Consent]: 5,
                [TOUR_STEPS.RoleInfo]: 6,
                [TOUR_STEPS.TourCompleted]: 7,
            };
        }
    } else if (MedeinaFeatures.EnableGeoSelection) {
        adminTourSteps = {
            [TOUR_STEPS.Welcome]: 1,
            [TOUR_STEPS.SelectLocation]: 2,
            [TOUR_STEPS.CreateCapacity]: 3,
            [TOUR_STEPS.SelectCapacity]: 3,
            [TOUR_STEPS.Locations]: 4,
            [TOUR_STEPS.DataAndPrivacy]: 5,
            [TOUR_STEPS.RoleInfo]: 6,
            [TOUR_STEPS.TourCompleted]: 7,
        };
    }
    return adminTourSteps;
};
