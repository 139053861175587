export type TenantFreRequirements = {
    allowProductImprovement?: boolean;
    allowModelImprovement?: boolean;
    freRequirementsMet?: boolean;
    freStage?: FreStage;
};

export type TenantInfo = {
    enabled?: boolean;
    tenantId: string;
    name: string;
    description?: string | null;
    featureFlags: string[];
    freRequirements: {
        allowModelImprovement?: boolean;
        allowProductImprovement?: boolean;
        freRequirementsMet?: boolean;
        freStage?: FreStage;
        optOutDate?: string;
    };
    allowTenantPluginUpload?: boolean;
    allowUserPluginUpload?: boolean;
    allowUserPluginManagement?: boolean;
    allowUserFileUpload?: boolean;
    allowSubmitFeedback?: boolean;
    complianceRequirements?: {
        allowO365DataCollection: {
            enabled: boolean;
        };
    } | null;
    mandatoryFeatureFlags?: string[];
    allowedRestrictedFeatureFlags?: string[];
    allowAuditLogging?: boolean;
};

export enum FreStage {
    TenantCreation = 'TenantCreation',
    CapacitySetup = 'CapacitySetup',
    WorkspaceSetup = 'WorkspaceSetup',
}
