import React, {useMemo, useState, useEffect} from 'react';
import {Tour} from '@/components/ui/Tour';
import {TourTelemetryOptions} from '@/components/ui/Tour/Tour.types';
// import tour stops
import EarlyExit from '../commonStops/EarlyExit';
import Welcome from './stops/Welcome';
import Setup from './stops/Setup';
import CapacitySelectionPage from './stops/CapacitySelection';
import LocationDisplay from './stops/LocationDisplay';
import DataAndPrivacy from './stops/DataAndPrivacy';
import ErrorPage from './stops/Error';
import {FreStage} from '@/api/tenant';
import Done from './stops/Done';
import {TOUR_ERRORS, TOUR_STEPS} from './stops/AdminTour.types';
import MedeinaFeatures, {MedeinaFeatureFlagValue} from '@/util/features';
import LocationSelection from './stops/LocationSelection/LocationSelection';

import {AccessRoleInfo} from './stops/AccessRoleInfo';
import {O365ConsentStep} from './stops/O365ConsentStep';
import {getAdminTourStops} from './AdminTour.util';
import AuditLoggingConsent from './stops/AuditLoggingOpting/AuditLoggingConsent';

export interface AdminTourProps {
    freStage?: FreStage;
    overrideStep?: TOUR_STEPS | null;
    telemetryOptions?: TourTelemetryOptions;
    isFreComplete?: boolean;
    workspaceName: string;
    isAuditLoggingEnabled: MedeinaFeatureFlagValue;
}

export function AdminTour(props: AdminTourProps): Tour {
    const {freStage, overrideStep, isFreComplete} = props;
    const adminTourSteps = getAdminTourStops(props.isAuditLoggingEnabled);

    const tourConfiguration: Tour = {
        name: 'AdminTour',
        onEarlyExit: <EarlyExit />,
        telemetryName: 'admin',
        telemetryOptions: props.telemetryOptions,
        errorPage: <ErrorPage />,
        errorStates: TOUR_ERRORS,
        tourStops: [
            {
                name: 'welcome',
                key: TOUR_STEPS.Welcome,
                autoAdvance: false,
                autoStart: true,
                content: (
                    <Welcome
                        isFreComplete={isFreComplete}
                        workspaceName={props.workspaceName || ''}
                    ></Welcome>
                ),
                simulateNetworkCalls: false,
                controls: {
                    showControls: false,
                },
                stepValue: adminTourSteps[TOUR_STEPS.Welcome],
            },
            {
                name: 'setupCapacity',
                key: TOUR_STEPS.CreateCapacity,
                autoAdvance: false,
                autoStart: true,
                content: <Setup />,
                simulateNetworkCalls: false,
                controls: {
                    showControls: false,
                },
                isFirstStop:
                    (overrideStep === TOUR_STEPS.CreateCapacity ||
                        freStage === FreStage.TenantCreation) &&
                    overrideStep !== TOUR_STEPS.SelectCapacity,
                stepValue: adminTourSteps[TOUR_STEPS.CreateCapacity],
            },
            {
                name: 'selectCapacity',
                key: TOUR_STEPS.SelectCapacity,
                autoAdvance: false,
                autoStart: true,
                content: <CapacitySelectionPage></CapacitySelectionPage>,
                simulateNetworkCalls: false,
                controls: {
                    showControls: false,
                },
                isFirstStop:
                    (overrideStep === TOUR_STEPS.SelectCapacity ||
                        freStage === FreStage.TenantCreation) &&
                    overrideStep !== TOUR_STEPS.CreateCapacity,
                stepValue: adminTourSteps[TOUR_STEPS.SelectCapacity],
            },
            {
                name: 'selectLocation',
                key: TOUR_STEPS.Locations,
                autoAdvance: false,
                autoStart: true,
                content: <LocationDisplay></LocationDisplay>,
                simulateNetworkCalls: false,
                controls: {
                    showControls: false,
                },
                isFirstStop: freStage === FreStage.CapacitySetup,
                stepValue: adminTourSteps[TOUR_STEPS.Locations],
            },
            {
                name: 'dataAndPrivacy',
                key: TOUR_STEPS.DataAndPrivacy,
                autoAdvance: false,
                autoStart: true,
                content: <DataAndPrivacy></DataAndPrivacy>,
                simulateNetworkCalls: false,
                controls: {
                    showControls: false,
                },
                stepValue: adminTourSteps[TOUR_STEPS.DataAndPrivacy],
            },
            {
                name: 'roleInfo',
                key: TOUR_STEPS.RoleInfo,
                autoAdvance: false,
                autoStart: true,
                content: <AccessRoleInfo></AccessRoleInfo>,
                simulateNetworkCalls: false,
                controls: {
                    showControls: false,
                },
                stepValue: adminTourSteps[TOUR_STEPS.RoleInfo],
            },
            {
                name: 'done',
                key: TOUR_STEPS.TourCompleted,
                autoAdvance: false,
                autoStart: true,
                content: <Done></Done>,
                simulateNetworkCalls: false,
                controls: {
                    showControls: false,
                },
                isFirstStop: freStage === FreStage.WorkspaceSetup,
                stepValue: adminTourSteps[TOUR_STEPS.TourCompleted],
            },
        ],
    };

    //Add the select location step to the second position of tour steps
    if (MedeinaFeatures.EnableGeoSelection) {
        const selectLocationStep = {
            name: 'selectLocation',
            key: TOUR_STEPS.SelectLocation,
            autoAdvance: false,
            autoStart: true,
            content: <LocationSelection></LocationSelection>,
            simulateNetworkCalls: false,
            controls: {
                showControls: false,
            },
            stepValue: adminTourSteps[TOUR_STEPS.SelectLocation],
        };

        tourConfiguration.tourStops.splice(1, 0, selectLocationStep);
    }

    if (MedeinaFeatures.EnableO365ConsentStepInFRE) {
        const o365ConsentStep = {
            name: 'O365Consent',
            key: TOUR_STEPS.O365Consent,
            autoAdvance: false,
            autoStart: true,
            content: <O365ConsentStep></O365ConsentStep>,
            simulateNetworkCalls: false,
            controls: {
                showControls: false,
            },
            isFirstStop: false,
            stepValue: adminTourSteps[TOUR_STEPS.O365Consent],
        };

        // When EnableGeoSelection feature flag is enabled, it will push that step.
        // Consequently, the index position for adding the O365Consent step will increase by 1.
        if (MedeinaFeatures.EnableGeoSelection) {
            tourConfiguration.tourStops.splice(6, 0, o365ConsentStep);
        } else {
            tourConfiguration.tourStops.splice(5, 0, o365ConsentStep);
        }
    }

    if (props.isAuditLoggingEnabled) {
        const auditLoggingConsentStep = {
            name: 'auditLoggingConsent',
            key: TOUR_STEPS.AuditLoggingConsent,
            autoAdvance: false,
            autoStart: true,
            content: <AuditLoggingConsent></AuditLoggingConsent>,
            simulateNetworkCalls: false,
            controls: {
                showControls: false,
            },
            isFirstStop: false,
            stepValue: adminTourSteps[TOUR_STEPS.AuditLoggingConsent],
        };
        // Follows the o365ConsentStep
        if (MedeinaFeatures.EnableGeoSelection && MedeinaFeatures.EnableO365ConsentStepInFRE) {
            tourConfiguration.tourStops.splice(7, 0, auditLoggingConsentStep);
        } else if (MedeinaFeatures.EnableO365ConsentStepInFRE) {
            tourConfiguration.tourStops.splice(6, 0, auditLoggingConsentStep);
        }
    }

    return tourConfiguration;
}

export default AdminTour;
