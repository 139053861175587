import React, {useRef, useEffect, useState} from 'react';
import {mergeClasses, useFocusFinders} from '@fluentui/react-components';
import usePageClasses from './AccessRoleInfo.styles';
import {CommunityIcon} from '@/components/ui/icons';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {motion} from 'framer-motion';
import {useTourContext, TourStageContainer, TourControls} from '@/components/ui/Tour';
import useManageTenantInfo from '@/api/tenant/useManageTenantInfo';
import {useTranslation} from 'react-i18next';
import {TOUR_ERRORS, TOUR_STEPS, TourErrorTypes} from '../AdminTour.types';
import useManageTransitionDetails from '../useManageTourTransition';
import {useNavigate} from 'react-router-dom';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useQueryClient} from '@tanstack/react-query';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {FreStage} from '@/api/tenant';
import {RoleInfoContent} from '.';
import {TextH1} from '@/components/ui/Text';

export function AccessRoleInfo() {
    const tour = useTourContext();

    const {setTourErrorV2} = useTourContext();

    const tourClasses = useTourClasses();
    const pageClasses = usePageClasses();

    const queryClient = useQueryClient();
    const {t} = useTranslation('tours');
    const {t: commonLabel} = useTranslation('common');
    const {validatePostWorkspaceSetup} = useManageTransitionDetails();
    const navigate = useNavigate();
    const {update: providerUpdate} = useWorkspaceState();

    const {patchFreMetadata} = useManageTenantInfo({
        onFetchTenantError: (error: any) => {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.RoleInfo,
            });
        },
    });

    const accessRoleInfoWrapper = useRef<HTMLDivElement>(null);
    const {findFirstFocusable} = useFocusFinders();
    // New loading state for the continue button
    const [isContinueLoading, setIsContinueLoading] = useState(false);
    useEffect(() => {
        if (accessRoleInfoWrapper?.current) {
            const firstFocusable = findFirstFocusable(accessRoleInfoWrapper.current);
            firstFocusable?.focus();
        }
    }, [accessRoleInfoWrapper]);

    async function handleContinueClick() {
        try {
            setIsContinueLoading(true);
            const nextStop = await validatePostWorkspaceSetup();
            if (nextStop === TOUR_STEPS.None) {
                await patchFreMetadata(FreStage.WorkspaceSetup);
                tour.nextStop();
            } else {
                if (nextStop === TOUR_STEPS.HOME) {
                    await providerUpdate();
                    await queryClient.invalidateQueries(['auth', 'getUserInfo']);
                    navigate('/');
                    tour.quitTour();
                } else {
                    tour.setTourStep(nextStop);
                }
            }
        } catch (error) {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.RoleInfo,
            });
        } finally {
            setIsContinueLoading(false); // Stop loading regardless of outcome
        }
    }

    function handleBackClick(): void {
        tour.prevStop();
    }

    return (
        <motion.div
            key="terms"
            variants={overlayAnimation}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <TourStageContainer ref={accessRoleInfoWrapper}>
                <TourStageContainer.StageContent>
                    <div>
                        <CommunityIcon
                            className={mergeClasses(
                                pageClasses.icon,
                                tourClasses.tourSectionSpacing,
                            )}
                        />
                    </div>
                    <div className={mergeClasses(tourClasses.tourSectionSpacing)}>
                        <TextH1>{t('adminV2Fre.roleInfo.Title')}</TextH1>
                    </div>
                    <RoleInfoContent />
                </TourStageContainer.StageContent>
                <TourStageContainer.FooterContent>
                    <TourControls
                        spanFullScreen={true}
                        previousButton={commonLabel('Back')}
                        nextButton={commonLabel('Continue')}
                        handlePrevious={handleBackClick}
                        handleNext={handleContinueClick}
                        isNextButtonDisabled={isContinueLoading}
                        showPrevious={!tour.currentStop?.isFirstStop}
                        showProgressSpinner={isContinueLoading}
                    ></TourControls>
                </TourStageContainer.FooterContent>
            </TourStageContainer>
        </motion.div>
    );
}

export default AccessRoleInfo;
