import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    supplementaryContentWidth: {
        alignSelf: 'center',
        maxWidth: '400px',
        width: '100%',
        textAlign: 'left',
    },
    roleContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        ...shorthands.gap(tokens.spacingHorizontalS),
        width: '100%',
    },
    contributorRolePersona: {
        width: '100%',
        maxWidth: '400px',
    },
    ownerRolePersona: {
        width: '194px',
    },
    icon: {
        height: '40px',
        width: '40px',
        '@media (forced-colors: active)': {
            backgroundColor: 'Canvas',
        },
    },
});
